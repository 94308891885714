.table-container-settlements{
    display: flex;
    justify-content: space-between;
    padding: 1em;
}

.form-contianer{
    margin: 1em;
    padding: 2em;
    background-color: rgb(28, 28, 28);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 5);
    color: white;
    border-radius: 0.5rem;
}