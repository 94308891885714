.side-nav {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  gap: 1em;
  width: 200px; /* Adjust width as needed */
  padding-top: 5em;
  position: relative;

  span {
    color: white;
    cursor: pointer;
    display: block;
    text-align: left;
    padding-left: 2em;
  }

  span:hover {
    font-weight: 700;
    border-right: solid gold 1px;
  }
}

.side-nav::after {
  content: '';
  position: absolute;
  right: 0;
  top: 90px;
  bottom: 20%;
  width: 0.2px;
  background-color: rgb(184, 184, 177);
}

@media (max-width: 600px) {
  .side-nav {
    flex-direction: row;
    justify-content: flex-end;
    width: 100%; 
    padding-top: 0; 
    overflow-x: auto; 
    font-size: 13px;
  }
  .side-nav-container {
    width: 100%; 
    
  }
}

@media (max-width: 320px) {
  /* Adjustments for very small screens, if needed */
  .MuiTableCell-root {
    padding: 2px;
    font-size: 0.75rem;
  }
 
}

