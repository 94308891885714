.table-container {
  display: flex;
  flex-direction: row; /* Default to row for larger screens */
  gap: 3em;
  padding: 1em;
  max-width: 100%;
  width: 100%;
}

.bal-head-container {
  padding: 1em;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .table-container {
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
}

/* Further adjust for very small screens */
@media (max-width: 480px) {
  .table-container {
    width: 100%;
    justify-content: center;
  }
}

@media (min-height: 1000px) {
  .table-container {
    margin-top: 10em;
  }
}
