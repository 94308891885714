.account-details-container{
    display: flex;
    flex-direction: column;
    padding: 2em;
    gap: 3em;
    width: 100%;
    background-color: rgb(28, 28, 28);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 5);
    border-radius: 0.5rem;
}

.account-section{
    line-height: 3em;
    h2{
        border-bottom: solid 1px white;
    }
}

.nav-container{
    padding: 1em;
}


.edit-button{
    width: 150px;
    
}
