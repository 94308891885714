form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  border-radius: 0.5rem;
  color: white;
  gap: 1em;
  width: 100%;
  height: 100%;

  input,
  select,
  button {
    width: 125px;
    height: 40px;
    padding: 0 10px;
    color: white;
    background-color: black;
    border-radius: 4px;
    border: none;
    cursor: pointer;
  }

  button {
    text-align: center;
    background-color: #ffb600;
    font-weight: 200;
    color: black;
  }
}

.form-field {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.close-button {
  display: flex;
  cursor: pointer;
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.reports-container {
  margin: 1em;
  border-radius: 0.5rem;
}

/* Adjustments for checkboxes */
input[type="checkbox"] {
  width: auto;
  height: auto;
  margin-top: 10px;
}

input[type="text"].input-style,
.react-datepicker__input-container input {
  outline: none;
  border: 1px solid transparent;
  box-sizing: border-box;
}

input[type="text"].input-style:focus,
.react-datepicker__input-container input:focus {
  border: 1px solid #ffb600;
  box-shadow: none;
}

/* Styles for hover effect on buttons */
button:hover {
  background-color: black;
  color: white;
}

@media (max-width: 768px) {
  form {
    display: none;
  }
}
