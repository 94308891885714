html, body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
    height: 100%;
    min-height: 100%;
    width: 100%;
    min-width: 100%;
  }

  .App{
    background-color: rgb(11, 11, 11);
    min-height: 100vh; 
    width: 100%;
    min-width: 100%;
  
}

/* Styles for the scrollbar track (background) */
::-webkit-scrollbar {
  width: 1px;
}

::-webkit-scrollbar-track {
  background: #000000; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #FFB600; 
  border-radius: 1px; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #FFB600; 
}
