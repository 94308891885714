.tableCell {
  font-size: 1rem;
}

.assetCell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 1em;
}

@media (max-width: 600px) {
  /* Adjust breakpoint as needed */
  .tableCell {
    font-size: 0.75rem;
    overflow-x: auto;
    width: 100%;
  }
}

@media (max-width: 320px) {
  .MuiTableCell-root {
    padding: 2px;
    font-size: 0.75rem;
    width: 100%;
    overflow-x: auto;
  }
}
