.balance-container {
  color: white;
  background-color: rgb(28, 28, 28);
  width: 100%; /* Change width to auto */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 5); /* Adjust box-shadow value */
  display: inline-block; /* Add this to make it shrink to content width */
  border-radius: 0.5rem;

  h1,
  h2 {
    font-weight: 300;
  }
}

.balance-display {
  padding: 2em;
  width: 100%; /* Change width to auto */
  box-sizing: border-box; /* Add this to include padding in width */
  font-weight: 700;
}

.bal-wrapper {
  display: flex;
}

.bal-display {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns */
  flex: 1; /* Adjust the flex ratio as needed */
}

.box {
  background-color: rgb(28, 28, 28);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 5);
  color: white;
  padding: 10px;
  margin: 5px;
  height: 200px;
  text-align: left;
  display: flex;
  flex-direction: column; /* Makes .box a flex container */
  padding: 2em;
  justify-content: center; /* Centers content horizontally */
  border-radius: 0.5rem;

  h2 {
    font-size: 18px;
  }

  span {
    font-size: 18px;
  }
}

.download-report {
  cursor: pointer;
}

.bal-chart {
  flex: 2;
  background-color: rgb(28, 28, 28);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 5);
  border-radius: 0.5rem;
  padding: 10px;
  margin: 5px;
}

.popup {
  position: absolute;
  top: 100%;  /* Position at the bottom of the bal-display */
  left: 0;
  width: 100%; /* Match width with the parent container */
  background-color: rgb(28, 28, 28);
  color: white;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  animation: fadeIn 0.5s ease-out forwards;
  z-index: 10; /* Ensure it's on top of other elements */
}

.popup h2 {
  font-size: 18px;
  font-weight: 300;
}

.popup p {
  font-size: 16px;
}

.popup button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #444;
  color: white;
  border: none;
  border-radius: 0.5rem;
  margin-top: 10px;
}



@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.box, .balance-display {
  animation: fadeIn 1.5s ease-out forwards; /* Adjust '1s' as needed for duration */
}

@media (max-width: 768px) {
  .bal-wrapper {
    display: flex;
    flex-direction: column;
  }

}





/* .balance-container {
    color: white;
    max-width: 960px;
    width: 100%;
    font-size: 0.7em;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 5%;
} */
