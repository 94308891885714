.account-contianer{
    color: white;
    height: 100vh;
    display: flex;
    padding: 2em;
}

.nav-container{
    display: flex;
    flex-direction: column;
}