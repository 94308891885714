.chart-layout {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 auto;
  padding-top: 5em;
}

.chart-container {
  flex: 1;
  justify-content: center;
  height: 200px;
}

.legend-container {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 50%;
  box-sizing: border-box;
  padding: 0 10px;
  line-height: 3em;
}

.legend-color {
  width: 20px;
  height: 20px;
  border: 1px solid #000;
}

.legend-label {
  font-size: 14px;
  color: #fff;
  margin-left: 10px;
}

.legend-color {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border: 1px solid #000;
}

.legend-label {
  font-size: 14px;
  color: #fff;
}

@media (max-width: 768px) {
  .chart-layout {
    flex-direction: column;
  }

  .chart-container {
    height: 300px;
  }

  .legend-container {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
  }

  .legend-item {
    width: 100%;
    justify-content: flex-start;
  }
}

@media (max-width: 480px) {
  .legend-item {
    flex-direction: row;
    justify-content: center;
    width: 50%;
    margin-bottom: 10px;
  }

  .legend-label {
    font-size: 12px;
  }
}
