.price-feed-container {
    display: flex;
    flex-direction: column; 
    background-color: rgba(28, 28, 28, 0.361);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 15);
    margin-top: 1em;
    width: 100%;
    color: white;
    overflow-x: auto;
    justify-content: space-around;
  }
  
  .price-feed-container ul {
    display: flex;
    flex-wrap: nowrap; 
    list-style: none;
    padding: 0;
    margin: 0; 
  }
  
  .price-feed-container li {
    display: flex;
    align-items: center; 
    justify-content: center; 
    flex: 1;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 1em;
    text-align: center;
  }

  .price-feed-container img {
    margin-right: 0.5em; 
  }
  
  /* Media query for devices with width less than 600px */
  @media (max-width: 600px) {
    .price-feed-container li {
      font-size: 12px; 
    }
  }
  
  /* Media query for tablet devices with width between 600px and 900px */
  @media (min-width: 601px) and (max-width: 900px) {
    .price-feed-container li {
      font-size: 12px; 
    }
  }

  
  /* Animation for pricefeed changes */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .fade-in {
    animation: fadeIn 1s;
  }