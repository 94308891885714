.navbar {
  display: flex;
  align-items: center;
  height: 70px;
  padding: 0 15px;
  padding-left: 5em;
  width: 100%;
  border-bottom: 1px solid #ffc32c;
}

.nav-menu {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 4em;
  margin-top: 1em;
  width: 500px;
}

.nav-link-item {
  flex-grow: 1;
  text-align: center;
}

.nav-link {
  color: white;
  width: 130px;
  font-weight: 200;
  margin: 0 10px;
  font-size: 18px;
  font-weight: 300;
  text-decoration: none;
  display: block;
  padding: 2px;
  border-radius: 3px; /* Consistent border radius */
  transition: background-color 0.3s ease, color 0.3s ease;
}

.nav-link:hover,
.nav-link.active {
  color: black;
  background-color: #ffb600;
}

/* Additional styles for the active class */
.active {
  color: black;
  background-color: #ffb600;
  border-radius: 3px;
}

.hamburger {
  display: none;
  position: absolute;
  right: 20px; /* Adjust as needed */
  top: 20px; /* Adjust as needed */
  z-index: 101; /* Ensure it's above the menu */
}

.mobile-nav {
  display: none;
}

@media (max-width: 768px) {
  .hamburger {
    display: block;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 102; /* Ensure it's above the .mobile-nav */
  }

  .nav-menu,
  .nav-link-item {
    display: none; /* Hide desktop nav */
  }

  .mobile-nav {
    display: flex;
    flex-direction: column;
    justify-content: start; /* Adjust based on design preference */
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgb(11, 11, 11);
    border-bottom: 1px solid #ffc32c;
    color: white;
    z-index: 101; /* Ensure it's below the hamburger menu */
  }

  .nav-link {
    color: white;
    font-size: 20px; /* Adjust based on design preference */
    padding: 10px;
    text-decoration: none;
    display: block;
    width: 100%;
    text-align: center; /* Center the links */
  }

  .nav-link:hover,
  .nav-link.active {
    background-color: #ffb600; /* Highlight color */
    color: black;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 5);
  }

  .mobile-nav-header {
    border-bottom: 1px solid #ffc32c;
    width: 100%;
    height: 80px;
    padding: 0 15px;
    padding-left: 5em;
    margin-bottom: 1em;
    padding-top: 0.9em;
  }
}
