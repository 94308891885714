.account-api-container {
    display: flex;
    flex-direction: column;
    padding: 2em;
    margin: 0 auto;
    line-height: 3em;

    h2{
        color: #FFC32C;
    }
  }
  
  .account-info {
    color: white;
    text-align: center;
  }
  